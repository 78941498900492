"use client";

import styles from "./CookiePreferencesButton.module.scss";
import CookieConsentBanner from "@/app_components/cookie/CookieConsentBanner";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { createPortal } from "react-dom";
import { useDocument } from "@/app_util/ssrHelper";
import { fadeInOut } from "@/app_util/fadeInOut";


const CONSENT_ACCEPTED = "accepted";
const CONSENT_REJECTED = "rejected";


function getConsentCookie(): string | undefined {
    const cookies = document.cookie.split("; ");
    const cookieConsent = cookies.find(row => row.startsWith("cookieConsent="));
    if (!cookieConsent)
        return undefined;

    return cookieConsent.split("=")[1];
}


function setConsentCookie(value: string): void {
    const now = new Date();
    const oneYearLater = new Date(now.setFullYear(now.getFullYear() + 1));
    document.cookie = `cookieConsent=${value};expires=${oneYearLater.toUTCString()};path=/`;
}


function removeConsentCookie(): void {
    document.cookie = "cookieConsent=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
}


export function listenForCookieConsent(callback: (consented: boolean) => void): () => void {
    const lastValue: { value: string | null } = { value: null };
    const intervalID = setInterval(() => {
        const value = getConsentCookie();
        if (value === undefined || value === lastValue.value)
            return;

        lastValue.value = value;
        callback(value === CONSENT_ACCEPTED);
    }, 1000);

    return () => {
        clearInterval(intervalID);
    };
}


export interface CookiePreferencesButtonProps {
    className: string | undefined;
}


export function CookiePreferencesButton({ className }: CookiePreferencesButtonProps) {
    const [showBanner, setShowBanner] = useState(false);

    const handlePreferencesButton = (value: string) => {
        setConsentCookie(value);
        setShowBanner(false);
    };

    const handleOpen = () => {
        removeConsentCookie();
        if (!showBanner) {
            setShowBanner(true);
        }
    };

    const doc = useDocument();
    return (
        <>
            {doc && createPortal(
                <div className={styles.banner_container}>
                    <AnimatePresence mode="wait">
                        {showBanner && (
                            <motion.div className={styles.banner_container} {...fadeInOut()}>
                                <CookieConsentBanner
                                    handleAccept={() => handlePreferencesButton(CONSENT_ACCEPTED)}
                                    handleReject={() => handlePreferencesButton(CONSENT_REJECTED)} />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>,
                doc.body,
            )}

            <button className={`${className} ${styles.button} ${showBanner ? styles.inactive : ""}`} onClick={handleOpen}>
                Cookie Settings
            </button>
        </>
    );
}
