import React from "react";
import styles from "./CookieConsentBanner.module.scss";
import Link from "next/link";
import RoyalUrButton from "@/app_components/generic/buttons/RoyalUrButton";


export interface CookieConsentBannerProps {
    handleAccept: () => void;
    handleReject: () => void;
}


export default function CookieConsentBanner({ handleAccept, handleReject }: CookieConsentBannerProps) {
    return (
        <div className={styles.banner_container}>
            <section className={styles.banner} aria-labelledby="cookie_consent_banner_heading">
                <div className={styles.description}>
                    <h2 id="cookie_consent_banner_heading" className={styles.title}>
                        Cookie Consent
                    </h2>
                    <p>
                        We use cookies to improve user experience and analyze website
                        traffic. By clicking &quot;Accept,&quot; you agree to our cookie
                        use as described in our
                        {" "}
                        <Link href="/cookies">
                            Cookie Policy.
                        </Link>
                    </p>
                </div>

                <div className={styles.buttons}>
                    <RoyalUrButton className={styles.button} style="cta" size="small" onClick={handleAccept}>
                        Accept All Cookies
                    </RoyalUrButton>

                    <RoyalUrButton className={styles.button} size="small" onClick={handleReject}>
                        Necessary Cookies
                    </RoyalUrButton>
                </div>
            </section>
        </div>
    );
}
